import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function CuddleImage({ imageData, imageAlt }) {
  if (imageData) {
    const image = getImage(imageData)

    return <GatsbyImage image={image} alt={imageAlt} />
  }
  return <></>
}

export default CuddleImage
