import * as React from "react"
import { graphql } from "gatsby"

import "@fontsource/pacifico"
import "@fontsource/itim"

import "../styles/page.css"
import "../styles/text.css"
import Blurb from "../components/Blurb"

function Content({ data }) {
  return (
    <>
      <header>
        <center>
          <h1>Cuddly Crochet</h1>
          <p>Custom cuddly crocheted creatures and other cute creations</p>
        </center>
      </header>
      <br />
      <main>
        <Blurb>
          <center>
            <h2>About Me</h2>
          </center>
          <p>
            Hi! My name is Sara and I make these cute little guys. These designs
            are from around the internet and some books. Crochet has given me so
            much joy and I hope to share that excitement and creativity with
            children and adults alike. I am still learning so let me know your
            ideas. I do this for <u>fun</u> and not profit.
          </p>
          <p>Some things about me:</p>
          <ul>
            <li>My favorite color is purple 💜</li>
            <li>I love my maltese 'Rosie' 🐕👧🏻</li>
            <li>
              I do <u>not</u> like spiders 🚫🕷️
            </li>
          </ul>
        </Blurb>
        {data.allImageSharp.nodes.map((img, i) => (
          <Blurb key={i} imageData={img} imageAlt="Cuddle" />
        ))}
      </main>
      <footer>
        <small>Made with ❤️ in Boston, MA</small>
      </footer>
    </>
  )
}

export const pageQuery = graphql`
  query {
    allImageSharp {
      nodes {
        gatsbyImageData(width: 800)
      }
    }
  }
`

export default Content
