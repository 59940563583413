import * as React from "react"
import CuddleImage from "./CuddleImage"

function Blurb({ children, imageData, imageAlt }) {
  return (
    <section>
      {children}
      <CuddleImage imageData={imageData} imageAlt={imageAlt} />
    </section>
  )
}

export default Blurb
